import { Component, Vue, Prop } from 'vue-property-decorator'
import Search from '@/components/Search/Search.vue'
@Component({
  components: {
    Search,
  },
})
export default class Header extends Vue {
  @Prop({ required: false })
  txtTittle!: string

  @Prop({ required: false })
  objMember!: any

  @Prop({ required: false })
  data!: Array<any>

  isViewPrincipal = true

  search = ''
  notNewBtn = false
  @Prop({ required: false, default: 'Nuevo' })
  btnTxt!: string

  showList = true
  loading = false

  openForm() {
    this.showList = false
    this.$emit('showForm')
  }

  cleanForm() {
    this.showList = true
    this.$emit('showList')
  }
  filterItems() {
    this.$emit('searchData', this.search)
  }
  filterItemsFront(items: any) {
    this.$emit('filterItemsFront', items)
  }
}
